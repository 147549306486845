<template>
  <div class="role_admin">
    <div class="page_body_area">
      <a-button
        type="primary"
        class="type_page_btn"
        @click="addNewDetail(-1, null)"
        >新增角色</a-button
      >
      <div class="page_table_area">
        <a-table
          rowKey="role_id"
          :columns="roleAdminColumns"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="tableLoading"
          size="middle"
        >
          <div slot-scope="data" slot="status">
            <a-switch
              checkedChildren="正常"
              unCheckedChildren="禁用"
              :checked="data.role_status == 1 ? true : false"
              @change="changeSwithStat($event, data.role_id)"
            />
          </div>
          <div slot-scope="data" slot="action">
            <a-button type="link" @click="addNewDetail(data.role_id, data)"
              >编辑</a-button
            >
            <a-button type="link" @click="delTableItem(data.role_id)"
              >删除</a-button
            >
          </div>
        </a-table>
      </div>
    </div>

    <EditRole ref="editRole" @editItem="getRoleList" />
  </div>
</template>

<script>
import { roleAdminColumns } from "./columns";
import EditRole from "./editRole";
// import * as systemRun from "@/api/systemRun";
export default {
  name: "roleAdmin",
  data() {
    return {
      roleAdminColumns,
      tableLoading: false,
      dataSource: [],
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
    };
  },
  created() {
    // this.getRoleList();
  },
  components: {
    EditRole,
  },
  methods: {
    getRoleList() {
      let data = {
        first_num: (this.pagination.current - 1) * this.pagination.pageSize,
        num: this.pagination.pageSize,
      };
      this.tableLoading = true;
      systemRun
        .getRolesList(data)
        .then((res) => {
          let dataSource = res.data.data.list;
          dataSource.map((item, index) => {
            return (item.indexNumber = index + 1);
          });
          this.dataSource = dataSource;
          this.pagination.total = res.data.data.total;
          this.tableLoading = false;
        })
        .catch((res) => {
          this.tableLoading = false;
          this.openNotification("error", res.data.errmsg);
        });
    },

    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    changePage(page, pageSize) {
      this.pagination.current = page;
      this.getRoleList();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getRoleList();
    },
    changeSwithStat(type, id) {
      this.tableLoading = true;
      let data = {
        role_id: id,
        status: type ? 1 : 0,
      };
      console.log(data);
      systemRun
        .updateRoleStatus(data)
        .then((res) => {
          this.getRoleList();
          this.openNotification("success", res.data.errmsg);
        })
        .catch((res) => {
          this.tableLoading = false;
          this.openNotification("error", res.data.errmsg);
        });
    },
    addNewDetail(id, data) {
      this.$refs.editRole.showDrawer(id, data);
    },
    delTableItem(id) {
      const _this = this;
      this.$confirm({
        title: "确认删除",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          return new Promise((resolve, reject) => {
            systemRun
              .delRole(id)
              .then((res) => {
                _this.openNotification("success", res.data.errmsg);
                _this.getRoleList();
                resolve();
              })
              .catch((res) => {
                _this.openNotification("error", "删除失败：" + res.data.errmsg);
                reject();
              });
          }).catch(() => {});
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.type_page_btn {
  margin-bottom: 10px;
}
</style>
